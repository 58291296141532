import "../styles/font-awesome.min.css";
import "../styles/bootstrap.min.css";
import "../styles/swiper.bundle.min.css";
import "../styles/main.css";
import $ from "jquery";

$(function () {

    function isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        }

        return false;
    }
    setTimeout(function() {
        $.get('https://feed.zlatnakopacka.mk/banners/banner_preview', function(html) {
            $(".baner-img span").text($(html).find("span").text().replace("ден.", ""));
        });
    },200);

    setInterval(function() {
        $.get('https://feed.zlatnakopacka.mk/banners/banner_preview', function(html) {
            $(".baner-img span").text($(html).find("span").text().replace("ден.", ""));
        });
    },10000);



    $(".baner-img").on("click", function() {
        location.href = 'https://zlatnakopacka.mk/promocii/jackpot';
    })


    function toggleMode() {
        const toggleSwitch = document.querySelector('.theme-switch input[type="checkbox"]');
        const currentTheme = localStorage.getItem('theme');

        if (currentTheme) {
            $("body").removeClass('dark');
            $("body").removeClass('light');
            $("body").addClass(currentTheme);

            if (currentTheme === 'dark') {
                toggleSwitch.checked = true;
            }
        }

        function switchTheme(e) {
            if (e.target.checked) {
                $("body").removeClass('light');
                $("body").addClass('dark');
                localStorage.setItem('theme', 'dark');
            }
            else {
                $("body").removeClass('dark');
                $("body").addClass('light');
                localStorage.setItem('theme', 'light');
            }
        }

        toggleSwitch.addEventListener('change', switchTheme, false);
    }

    //toggleMode();

    if (isMobile()) {
        $(".login-reg-buttons").hide();
    }

    $(".navbar-brand img").on("click", function () {
        location.href = location.origin;
    });

    var mybutton = document.getElementById("scrollTop");

    window.onscroll = function () {
        scrollFunction()
    };

    function scrollFunction() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 20) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }

    $("#scrollTop").on("click", function () {
        $("html, body").animate({
            scrollTop: "0"
        });
    })

    try {
        const global_menu = async () => {
            const api_url = 'https://news.zlatnakopacka.mk/wp-json/api/v1/menu';
            const response = await fetch(api_url).then(e => e.json());

            main_menu(response.header_menu);
            footer(response.footer)

        }
        global_menu();
    } catch (e) {
        console.error(e);
    }

    async function main_menu(data) {
        const menu = $(".navbar-nav")
        const dropdown = $(".dropdown-menu");
        var children = [];
        data.map((e, i) => {
            const {
                title,
                url,
                ID
            } = e
            children = e.children
            menu.append(`<li class="nav-item ${children.length ? `dropdown ${ID}_dropdown` : ''}">
                                    <a class="nav-link ${children.length ? 'dropdown-toggle' : ''}" href="${url}" ${children.length ? ` id="${title}Drop" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"` : ``}>${title}</a>
                                    `)
            if (children.length) {
                $(`.${ID}_dropdown`).append(`<div class="dropdown-menu ${ID}_drop" aria-labelledby="${title}Drop">`)
                children.map((e, i) => {
                    const {
                        title,
                        url
                    } = e
                    $(`.${ID}_drop`).append(`<a class="dropdown-item" href="${url}">${title}</a>`)
                })
                $(`.${ID}_dropdown`).append(`</div></li>`)
            } else {
                menu.append('</li>')
            }

        })
    }

    async function footer(data) {
        const footer_menu = $(".footer_menu")
        const about = $(".footer_about_us")
        const social = $(".footer-socials")

        $(".android_app").attr("href", data.android_app);
        $(".ios_app").attr("href", data.ios_app);

        data.menu.map((e, i) => {
            footer_menu.append(`<li><a href="${e.url}">${e.title}</a></li>`)
        });

        about.html(data.about_us)

        data.social.map((e, i) => {
            social.append(`<a href="${e.url}" target="_blank"><img src="${e.icon}" alt="" class="img-fluid"></a>`)
        });


    }
})